var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "ecommerce-checkout-demo" } },
    [
      _c(
        "form-wizard",
        {
          ref: "checkoutWizard",
          attrs: {
            color: "rgba(var(--vs-primary), 1)",
            title: null,
            subtitle: null,
            "hide-buttons": true,
          },
        },
        [
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: { title: "Cart", icon: "feather icon-shopping-cart" },
            },
            [
              _vm.cartItems.length
                ? _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col lg:w-2/3 w-full relative" },
                      _vm._l(_vm.cartItems, function (item, index) {
                        return _c(
                          "div",
                          {
                            key: item.objectID,
                            staticClass: "items-list-view",
                          },
                          [
                            _c(
                              "item-list-view",
                              { staticClass: "mb-base", attrs: { item: item } },
                              [
                                _c(
                                  "template",
                                  { slot: "item-meta" },
                                  [
                                    _c(
                                      "h6",
                                      {
                                        staticClass:
                                          "item-name font-semibold mb-1 cursor-pointer hover:text-primary",
                                        on: {
                                          click: function ($event) {
                                            _vm.$router
                                              .push({
                                                name: "ecommerce-item-detail-view",
                                                params: {
                                                  item_id: item.objectID,
                                                },
                                              })
                                              .catch(() => {})
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(item.name))]
                                    ),
                                    _c("p", { staticClass: "text-sm mb-2" }, [
                                      _vm._v("By "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "font-semibold cursor-pointer",
                                        },
                                        [_vm._v(_vm._s(item.brand))]
                                      ),
                                    ]),
                                    _c(
                                      "p",
                                      { staticClass: "text-success text-sm" },
                                      [_vm._v("In Stock")]
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "mt-4 font-bold text-sm" },
                                      [_vm._v("Quantity")]
                                    ),
                                    _c("vs-input-number", {
                                      staticClass: "inline-flex",
                                      attrs: {
                                        min: "1",
                                        max: "10",
                                        value: item.quantity,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.updateItemQuantity(
                                            $event,
                                            index
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "font-medium text-grey mt-4",
                                      },
                                      [
                                        _vm._v(
                                          "Delivery by, " +
                                            _vm._s(item.delivery_date)
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-success font-medium",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(item.discount_in_percentage) +
                                            "% off " +
                                            _vm._s(item.offers_count) +
                                            " offers Available"
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c("template", { slot: "action-buttons" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-view-primary-action-btn p-3 rounded-lg flex flex-grow items-center justify-center cursor-pointer mb-3",
                                      on: {
                                        click: function ($event) {
                                          return _vm.removeItemFromCart(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "XIcon",
                                          svgClasses: "h-4 w-4",
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-sm font-semibold ml-2",
                                        },
                                        [_vm._v("REMOVE")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "item-view-secondary-action-btn bg-primary p-3 rounded-lg flex flex-grow items-center justify-center text-white cursor-pointer",
                                      on: {
                                        click: function ($event) {
                                          return _vm.wishListButtonClicked(item)
                                        },
                                      },
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "HeartIcon",
                                          svgClasses: [
                                            {
                                              "text-white fill-current":
                                                _vm.isInWishList(item.objectID),
                                            },
                                            "h-4 w-4",
                                          ],
                                        },
                                      }),
                                      _vm.isInWishList(item.objectID)
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-sm font-semibold ml-2",
                                            },
                                            [_vm._v("WISHLIST")]
                                          )
                                        : _c(
                                            "span",
                                            {
                                              staticClass:
                                                "text-sm font-semibold ml-2",
                                            },
                                            [_vm._v("WISHLIST")]
                                          ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              2
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col lg:w-1/3 w-full" },
                      [
                        _c(
                          "vx-card",
                          [
                            _c("p", { staticClass: "text-grey mb-3" }, [
                              _vm._v("Options"),
                            ]),
                            _c("div", { staticClass: "flex justify-between" }, [
                              _c("span", { staticClass: "font-semibold" }, [
                                _vm._v("Coupons"),
                              ]),
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "font-medium text-primary cursor-pointer",
                                },
                                [_vm._v("Apply")]
                              ),
                            ]),
                            _c("vs-divider"),
                            _c("p", { staticClass: "font-semibold mb-3" }, [
                              _vm._v("Price Details"),
                            ]),
                            _c(
                              "div",
                              { staticClass: "flex justify-between mb-2" },
                              [
                                _c("span", { staticClass: "text-grey" }, [
                                  _vm._v("Total MRP"),
                                ]),
                                _c("span", [_vm._v("$598")]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex justify-between mb-2" },
                              [
                                _c("span", { staticClass: "text-grey" }, [
                                  _vm._v("Bag Discount"),
                                ]),
                                _c("span", { staticClass: "text-success" }, [
                                  _vm._v("-25$"),
                                ]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex justify-between mb-2" },
                              [
                                _c("span", { staticClass: "text-grey" }, [
                                  _vm._v("Estimated Tax"),
                                ]),
                                _c("span", [_vm._v("$1.3")]),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex justify-between mb-2" },
                              [
                                _c("span", { staticClass: "text-grey" }, [
                                  _vm._v("EMI Eligibility"),
                                ]),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-primary",
                                    attrs: { href: "#" },
                                  },
                                  [_vm._v("Details")]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "flex justify-between mb-2" },
                              [
                                _c("span", { staticClass: "text-grey" }, [
                                  _vm._v("Delivery Charges"),
                                ]),
                                _c("span", { staticClass: "text-success" }, [
                                  _vm._v("Free"),
                                ]),
                              ]
                            ),
                            _c("vs-divider"),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex justify-between font-semibold mb-3",
                              },
                              [
                                _c("span", [_vm._v("Total")]),
                                _c("span", [_vm._v("$574.3")]),
                              ]
                            ),
                            _c(
                              "vs-button",
                              {
                                staticClass: "w-full",
                                on: {
                                  click: function ($event) {
                                    return _vm.$refs.checkoutWizard.nextTab()
                                  },
                                },
                              },
                              [_vm._v("PLACE ORDER")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ])
                : _c(
                    "vx-card",
                    {
                      attrs: {
                        title: "You don't have any items in your cart.",
                      },
                    },
                    [
                      _c(
                        "vs-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.$router
                                .push("/apps/eCommerce/shop")
                                .catch(() => {})
                            },
                          },
                        },
                        [_vm._v("Browse Shop")]
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: { title: "Address", icon: "feather icon-home" },
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col lg:w-2/3 w-full" },
                  [
                    _c(
                      "vx-card",
                      {
                        staticClass: "mb-base",
                        attrs: {
                          title: "Add New Address",
                          subtitle:
                            'Be sure to check "Deliver to this address" when you have finished',
                        },
                      },
                      [
                        _c(
                          "form",
                          { attrs: { "data-vv-scope": "add-new-address" } },
                          [
                            _c("div", { staticClass: "vx-row" }, [
                              _c(
                                "div",
                                { staticClass: "vx-col sm:w-1/2 w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|alpha_spaces",
                                        expression: "'required|alpha_spaces'",
                                      },
                                    ],
                                    staticClass: "w-full mt-5",
                                    attrs: {
                                      "data-vv-as": "field",
                                      name: "fullName",
                                      label: "Full Name:",
                                    },
                                    model: {
                                      value: _vm.fullName,
                                      callback: function ($$v) {
                                        _vm.fullName = $$v
                                      },
                                      expression: "fullName",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "add-new-address.fullName"
                                          ),
                                          expression:
                                            "errors.has('add-new-address.fullName')",
                                        },
                                      ],
                                      staticClass: "text-danger",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            "add-new-address.fullName"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "vx-col sm:w-1/2 w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|digits:10",
                                        expression: "'required|digits:10'",
                                      },
                                    ],
                                    staticClass: "w-full mt-5",
                                    attrs: {
                                      name: "mobileNum",
                                      label: "Mobile Number:",
                                    },
                                    model: {
                                      value: _vm.mobileNum,
                                      callback: function ($$v) {
                                        _vm.mobileNum = $$v
                                      },
                                      expression: "mobileNum",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "add-new-address.mobileNum"
                                          ),
                                          expression:
                                            "errors.has('add-new-address.mobileNum')",
                                        },
                                      ],
                                      staticClass: "text-danger",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            "add-new-address.mobileNum"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "vx-row" }, [
                              _c(
                                "div",
                                { staticClass: "vx-col sm:w-1/2 w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-full mt-5",
                                    attrs: {
                                      name: "houseNum",
                                      label: "Flat, House No:",
                                    },
                                    model: {
                                      value: _vm.houseNum,
                                      callback: function ($$v) {
                                        _vm.houseNum = $$v
                                      },
                                      expression: "houseNum",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "add-new-address.houseNum"
                                          ),
                                          expression:
                                            "errors.has('add-new-address.houseNum')",
                                        },
                                      ],
                                      staticClass: "text-danger",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            "add-new-address.houseNum"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "vx-col sm:w-1/2 w-full" },
                                [
                                  _c("vs-input", {
                                    staticClass: "w-full mt-5",
                                    attrs: {
                                      name: "landmark",
                                      label:
                                        "Landmark e.g. near apollo hospital:",
                                    },
                                    model: {
                                      value: _vm.landmark,
                                      callback: function ($$v) {
                                        _vm.landmark = $$v
                                      },
                                      expression: "landmark",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "vx-row" }, [
                              _c(
                                "div",
                                { staticClass: "vx-col sm:w-1/2 w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-full mt-5",
                                    attrs: {
                                      name: "city",
                                      label: "Town/City:",
                                    },
                                    model: {
                                      value: _vm.city,
                                      callback: function ($$v) {
                                        _vm.city = $$v
                                      },
                                      expression: "city",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "add-new-address.city"
                                          ),
                                          expression:
                                            "errors.has('add-new-address.city')",
                                        },
                                      ],
                                      staticClass: "text-danger",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            "add-new-address.city"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "vx-col sm:w-1/2 w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required|min:3|max:6|numeric",
                                        expression:
                                          "'required|min:3|max:6|numeric'",
                                      },
                                    ],
                                    staticClass: "w-full mt-5",
                                    attrs: {
                                      name: "pincode",
                                      label: "Pincode:",
                                    },
                                    model: {
                                      value: _vm.pincode,
                                      callback: function ($$v) {
                                        _vm.pincode = $$v
                                      },
                                      expression: "pincode",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "add-new-address.pincode"
                                          ),
                                          expression:
                                            "errors.has('add-new-address.pincode')",
                                        },
                                      ],
                                      staticClass: "text-danger",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            "add-new-address.pincode"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c("div", { staticClass: "vx-row" }, [
                              _c(
                                "div",
                                { staticClass: "vx-col sm:w-1/2 w-full" },
                                [
                                  _c("vs-input", {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: "required",
                                        expression: "'required'",
                                      },
                                    ],
                                    staticClass: "w-full mt-5",
                                    attrs: { name: "state", label: "State:" },
                                    model: {
                                      value: _vm.state,
                                      callback: function ($$v) {
                                        _vm.state = $$v
                                      },
                                      expression: "state",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.errors.has(
                                            "add-new-address.state"
                                          ),
                                          expression:
                                            "errors.has('add-new-address.state')",
                                        },
                                      ],
                                      staticClass: "text-danger",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.errors.first(
                                            "add-new-address.state"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "vx-col sm:w-1/2 w-full" },
                                [
                                  _c(
                                    "vs-select",
                                    {
                                      staticClass: "w-full mt-5",
                                      attrs: { label: "Address Type:" },
                                      model: {
                                        value: _vm.addressType,
                                        callback: function ($$v) {
                                          _vm.addressType = $$v
                                        },
                                        expression: "addressType",
                                      },
                                    },
                                    _vm._l(
                                      _vm.addressTypeOptions,
                                      function (item, index) {
                                        return _c("vs-select-item", {
                                          key: index,
                                          attrs: {
                                            value: item.value,
                                            text: item.text,
                                          },
                                        })
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                            _c(
                              "vs-button",
                              {
                                staticClass: "mt-6 ml-auto flex",
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.submitNewAddressForm.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [_vm._v("SAVE AND DELIVER HERE")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col lg:w-1/3 w-full" },
                  [
                    _c(
                      "vx-card",
                      { attrs: { title: "John Doe" } },
                      [
                        _c("div", [
                          _c("p", [_vm._v("9447 Glen Eagles Drive")]),
                          _c("p", [_vm._v("Lewis Center, OH 43035")]),
                          _c("p", { staticClass: "my-4" }, [
                            _vm._v("UTC-5: Eastern Standard Time (EST)"),
                          ]),
                          _c("p", [_vm._v("202-555-0140")]),
                        ]),
                        _c("vs-divider"),
                        _c(
                          "vs-button",
                          {
                            staticClass: "w-full",
                            on: {
                              click: function ($event) {
                                return _vm.$refs.checkoutWizard.nextTab()
                              },
                            },
                          },
                          [_vm._v("DELIVER TO THIS ADDRESS")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "tab-content",
            {
              staticClass: "mb-5",
              attrs: { title: "Payment", icon: "feather icon-credit-card" },
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col lg:w-2/3 w-full" },
                  [
                    _c(
                      "vx-card",
                      {
                        staticClass: "mb-base",
                        attrs: {
                          title: "Payment Options",
                          subtitle:
                            "Be sure to click on correct payment option",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mt-3" },
                          [
                            _c(
                              "ul",
                              [
                                _c("li", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex flex-wrap justify-between items-center",
                                    },
                                    [
                                      _c(
                                        "vs-radio",
                                        {
                                          attrs: { "vs-value": "debit-card" },
                                          model: {
                                            value: _vm.paymentMethod,
                                            callback: function ($$v) {
                                              _vm.paymentMethod = $$v
                                            },
                                            expression: "paymentMethod",
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "flex items-center",
                                            },
                                            [
                                              _c("img", {
                                                staticClass: "inline-flex",
                                                attrs: {
                                                  src: require("@/assets/images/pages/eCommerce/bank.png"),
                                                  alt: "bank-logo",
                                                  height: "40",
                                                  width: "50",
                                                },
                                              }),
                                              _c("span", [
                                                _vm._v(
                                                  "US Unlocked Debit Card 12XX XXXX XXXX 0000"
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("span", [_vm._v("John Doe")]),
                                      _c("span", [_vm._v("11/2020")]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "form",
                                    { attrs: { "data-vv-scope": "cvv-form" } },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex items-center flex-wrap",
                                        },
                                        [
                                          _c("span", { staticClass: "mt-4" }, [
                                            _vm._v("Enter CVV: "),
                                          ]),
                                          _c("vs-input", {
                                            directives: [
                                              {
                                                name: "validate",
                                                rawName: "v-validate",
                                                value: "required|digits:3",
                                                expression:
                                                  "'required|digits:3'",
                                              },
                                            ],
                                            staticClass: "mr-3 ml-2 mt-4",
                                            attrs: { name: "cvv" },
                                            model: {
                                              value: _vm.cvv,
                                              callback: function ($$v) {
                                                _vm.cvv = $$v
                                              },
                                              expression: "cvv",
                                            },
                                          }),
                                          _c(
                                            "vs-button",
                                            {
                                              staticClass: "mt-4",
                                              attrs: { disabled: false },
                                              on: {
                                                click: function ($event) {
                                                  $event.preventDefault()
                                                  return _vm.makePayment.apply(
                                                    null,
                                                    arguments
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("CONTINUE")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.errors.has("cvv-form.cvv"),
                                              expression:
                                                "errors.has('cvv-form.cvv')",
                                            },
                                          ],
                                          staticClass: "text-danger ml-24",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.errors.first("cvv-form.cvv")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                                _c("vs-divider", { staticClass: "my-6" }),
                                _c(
                                  "li",
                                  [
                                    _c(
                                      "vs-radio",
                                      {
                                        attrs: {
                                          "vs-value": "credit-debit-atmCard",
                                        },
                                        model: {
                                          value: _vm.paymentMethod,
                                          callback: function ($$v) {
                                            _vm.paymentMethod = $$v
                                          },
                                          expression: "paymentMethod",
                                        },
                                      },
                                      [_vm._v("Credit / Debit / ATM Card")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "li",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "vs-radio",
                                      {
                                        attrs: { "vs-value": "netBanking" },
                                        model: {
                                          value: _vm.paymentMethod,
                                          callback: function ($$v) {
                                            _vm.paymentMethod = $$v
                                          },
                                          expression: "paymentMethod",
                                        },
                                      },
                                      [_vm._v("Net Banking")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "li",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "vs-radio",
                                      {
                                        attrs: { "vs-value": "emi" },
                                        model: {
                                          value: _vm.paymentMethod,
                                          callback: function ($$v) {
                                            _vm.paymentMethod = $$v
                                          },
                                          expression: "paymentMethod",
                                        },
                                      },
                                      [_vm._v("EMI (Easy Installment)")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "li",
                                  { staticClass: "mt-2" },
                                  [
                                    _c(
                                      "vs-radio",
                                      {
                                        attrs: { "vs-value": "cashOnDelivery" },
                                        model: {
                                          value: _vm.paymentMethod,
                                          callback: function ($$v) {
                                            _vm.paymentMethod = $$v
                                          },
                                          expression: "paymentMethod",
                                        },
                                      },
                                      [_vm._v("Cash On Delivery")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("vs-divider"),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "inline-flex items-center cursor-pointer",
                              },
                              [
                                _c("feather-icon", {
                                  staticClass: "mr-2",
                                  attrs: {
                                    icon: "PlusSquareIcon",
                                    svgClasses: "h-6 w-6",
                                  },
                                }),
                                _c("span", [_vm._v("Add Gift Card")]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col lg:w-1/3 w-full" },
                  [
                    _c(
                      "vx-card",
                      { attrs: { title: "Price Details" } },
                      [
                        _c(
                          "div",
                          { staticClass: "flex justify-between mb-2" },
                          [
                            _c("span", [_vm._v("Price 3 Items")]),
                            _c("span", { staticClass: "font-semibold" }, [
                              _vm._v("$699.30"),
                            ]),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "flex justify-between mb-2" },
                          [
                            _c("span", [_vm._v("Delivery Charges")]),
                            _c("span", { staticClass: "text-success" }, [
                              _vm._v("Free"),
                            ]),
                          ]
                        ),
                        _c("vs-divider"),
                        _c("div", { staticClass: "flex justify-between" }, [
                          _c("span", [_vm._v("Amount Payable")]),
                          _c("span", { staticClass: "font-semibold" }, [
                            _vm._v("$699.30"),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }